export const CONTROL_ICONS = {
  ERROR: '$status_error',
  REQUESTED: '$status_requested',
  PROCESSING: '$status_processing',
  DONE: '$enabled'
};

export const getControlIcon = (ack, done, error) => {
  if ([ack, done, error].some(item => item === undefined)) return '';
  if (error) return CONTROL_ICONS.ERROR;
  if (done && !error) return CONTROL_ICONS.DONE;
  if (!ack && !done) return CONTROL_ICONS.REQUESTED;
  if (ack && !done) return CONTROL_ICONS.PROCESSING;
  return '';
};
