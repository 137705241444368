import {
  onActivated,
  onBeforeUnmount,
  onDeactivated
} from '@vue/composition-api';

export function useTimerFunction(func, { timeout = 5000, immediate = true }) {
  let _timerId = null;
  let _immediate = immediate;
  let _paused = false;

  const run = async () => {
    if (!_timerId) {
      if (_immediate) {
        _immediate = false;
        await func();
      }
    }
    _timerId = setTimeout(async () => {
      _timerId = null;
      await func();
      run();
    }, timeout);
  };

  const stop = () => {
    clearTimeout(_timerId);
    _immediate = true;
    _timerId = null;
  };

  onActivated(() => {
    if (_paused) {
      run();
      _paused = false;
    }
  });

  onDeactivated(() => {
    _paused = true;
    stop();
  });

  onBeforeUnmount(() => {
    stop();
  });

  return {
    run,
    stop
  };
}
